import React from "react";

const Courses = () => {
  return (
    <div>
      {" "}
      <h1 style={{ fontSize: "18rem", color: "blue" }}>Recipe Vault</h1>
    </div>
  );
};

export default Courses;
